import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import * as types from '@survey/store/types'
import api from '@survey/api'
import { SurveyQuestionForm, SurveyWriteHeader } from '@survey/components'
// import SurveyPreviewImageModalView from '@survey/views/ModalViews/SurveyPreviewImageModalView.vue'



export interface QuestionOrderObj {
    questionId: number,
    currentOrders: number
}




@Component({
    components: {
        SurveyQuestionForm,
        SurveyWriteHeader
        // SurveyPreviewImageModalView
    },
    computed: {
        ...mapGetters({
            createdSurveyId: types.SURVEY_CREATED_SURVEYID,
            createdQuestions: types.SURVEY_QUESTION
        })
    },
    methods: {
        ...mapActions({
            createQuestion: types.SURVEY_QUESTION,
            setSurveyId: types.SURVEY_CREATED_SURVEYID,
            initQuestion: types.SURVEY_INIT_QUESTION
        }),
        ...mapMutations({
            resorting: types.SURVEY_RESORTING_QUESTION
        })
    },
})
export default class SurveyWriteS2View extends Vue {

    resorting!: () => void
    initQuestion!: (surveyId: number) => Promise<void>
    createQuestion!: (data: survey.SurveyQuestionRequest) => void
    setSurveyId!: (surveyId?: number) => void

    createdQuestions!: Array<survey.SurveyQuestionDetail>
    createdSurveyId!: number
    lastQuestionOrder: number = 1
    showModal: boolean = false
    selectedAttachment: survey.SurveyAttachmentInfo = Object.create(null)
    isPopup: boolean = false

    //직접 주소 및 쿼리를 입력하고 오는 경우 리스트 화면으로 보냄
    async created() {
        //팝업인지 체크
        this.isPopup = this.$route.path.toLowerCase().includes('popup')


        this.setPage({
            title: this.$t('설문작성').toString()
        })

        if (typeof (this.createdSurveyId) == 'object' || this.createdSurveyId == null) {
            await (window as any).alertAsync(this.$t('잘못된_접근입니다').toString())
            this.$router.push({
                name: "SurveyList"
            })
        }

        //초기 데이터 
        this.initQuestion(this.createdSurveyId || parseInt(this.$route.query.surveyId as string)).then(result => {
            //마지막 순서 초기화
            //watch 추가됨에 따라 필요없음 
            //this.initQuestionOrder()
        })

    }

    initQuestionOrder() {

        let maxOrders: number = 1

        maxOrders = Math.max(...this.createdQuestions.map(item => {
            return item.questionInfo.questionOrders
        }, 0))


        if (!Number.isFinite(maxOrders)) {
            maxOrders = 0
        }

        this.lastQuestionOrder = maxOrders + 1

    }

    goStep01() {

        this.$router.push({
            name: "SurveyWriteStep01",
            query: {
                surveyId: this.createdSurveyId.toString(),
                MENUID: this.$route.query.MENUID
            }
        })
    }

    goBack() {
        if (this.isPopup) {
            window.close()
        } else {
            this.$router.push({
                name: "SurveyList",
                query: {
                    MENUID: this.$route.query.MENUID
                }
            })
        }
    }

    async goList() {

        if (await(window as any).confirmAsync(this.$t('저장하지_않고_목록으로_이동하면_작성_중_상태가_유지_됩니다_').toString())) {
            this.goBack()
        }
    }

    async deleteHard() {

        if (await(window as any).confirmAsync(this.$t('저장하지않고_취소').toString())) {

            if (typeof (this.createdSurveyId) == 'object') {
                this.goBack()
                return
            }

            api.survey.deleteHardSurvey(this.createdSurveyId).then(async result => {
                if (result) {
                    this.setSurveyId()
                    this.goBack()
                } else {

                    await (window as any).alertAsync(this.$t('설문_삭제를_실패').toString())
                }

            })
        }
    }


    addQuestion() {

        let initVal = {
            surveyId: this.createdSurveyId,
            questionOrders: this.lastQuestionOrder,
            questionType: 0,
            isAdditionalOption: false,
            questionText: ""
        }

        this.createQuestion(
            {
                questionInfo: initVal
            }
        )
        //문항이 추가 될 때 마다 마지막 순서를 기억했다가 + 함
        this.lastQuestionOrder++
    }

    async save() {
        //실제 데이터는 작성 당시 이미 DB 에 모두 저장되고
        //해당 function 에 기능은 TC_SURVEY 테이블 IsComposing Flag 를 변경
        //IsComposing = true 면 리스트화면에 노출 안됨

        if (await(window as any).confirmAsync(this.$t('저장_하시겠습니까').toString())) {
            //유효성체크
            if (this.validation()) {
                api.survey.setSurveyComposingStatus({
                    surveyId: this.createdSurveyId,
                    isComposing: false
                }).then(async result => {
                    if (result) {
                        this.goBack()
                    } else {
                        await (window as any).alertAsync(this.$t('저장_실패').toString())
                    }
                })
            } else {
                await (window as any).alertAsync(
                    this.$t('빈_값이나_보기_없음').toString())
            }

        }
    }

    validation() {
        try {

            //질문이 생성 안된 경우
            if (this.createdQuestions.length == 0) {
                throw new Error()
            }

            //질문에 text가 입력 안된 경우
            this.createdQuestions.forEach(item => {
                if (item.questionInfo.questionText == "") {
                    throw new Error()
                }
            })

            //서술형이 아닌데 보기가 없는 경우
            this.createdQuestions.forEach(item => {
                //현재는 2번 타입만 서술형
                if (item.questionInfo.questionType != 2) {
                    if (item.examples.length == 0) {
                        throw new Error()
                    }
                }
            })

            //보기가 있는데 텍스트가 입력 안된 경우
            this.createdQuestions.forEach(item => {
                //현재는 2번 타입만 서술형
                if (item.questionInfo.questionType != 2) {
                    item.examples.forEach(exam => {
                        if (exam.exampleText == "") {
                            throw new Error()
                        }
                    })
                }
            })


        } catch (err) {
            return false
        }

        return true
    }

    changeQuestionOrders(targetObj: QuestionOrderObj, isUp: boolean) {

        //targetObj
        // questionId : 0,
        // currentOrders : 1

        //예외상황
        //첫번째 항목인데 순서를 올리는 경우
        //마지막 항목인데 순서를 내리는 경우

        if (targetObj.currentOrders == 1 && isUp ||
            targetObj.currentOrders == this.lastQuestionOrder - 1 && !isUp) {

            return
        }

        //분기 올리냐 내리냐
        //if(isUp){
        //올리는건 order 가 -1
        //}else{
        // 내리는건 order +1
        //변경 대상이 있는 경우 체크해서 변경해야됨
        // 1순위에서 2순위로 변경되면 기존 2순위를 1순위로 변경 서로 swap
        let existsQuestion = this.createdQuestions.filter(item => {
            return item.questionInfo.questionOrders == targetObj.currentOrders + (isUp ? -1 : 1)
        })

        //변경 대상이 있기 때문에 먼저 변경
        if (existsQuestion.length > 0) {
            existsQuestion[0].questionInfo.questionOrders = existsQuestion[0].questionInfo.questionOrders + (isUp ? 1 : -1)
            this.createQuestion(existsQuestion[0])
        }

        let question = this.createdQuestions.filter(item => {
            return item.questionInfo.surveyQuestionId == targetObj.questionId
        })

        if (question.length > 0) {

            question[0].questionInfo.questionOrders = question[0].questionInfo.questionOrders + (isUp ? -1 : 1)
            this.createQuestion(question[0])
        }

        this.resorting()
        //force re-render

        // Vue.set(this.createdQuestions,)
        //this.createdQuestions.slice().reverse()

        //}



    }

    orderAdjustment(targetObj: QuestionOrderObj) {
        //alert(questionId)

        this.createdQuestions.forEach(question => {
            if (<number>question.questionInfo.questionOrders > targetObj.currentOrders) {
                //alert(question.questionInfo.questionOrders)

                this.changeQuestionOrders({
                    questionId: <number>question.questionInfo.surveyQuestionId,
                    currentOrders: question.questionInfo.questionOrders
                }, true)
            }
        })
    }

    @Watch("createdQuestions")
    onChagnedQuestions() {
        this.initQuestionOrder()
    }

    previewImg(data: survey.SurveyAttachmentInfo) {
        this.selectedAttachment = data

        this.showModal = true
    }

    deleteImg() {
        //하위  컴포넌트 호출
        let refValue = `questionForm${this.selectedAttachment.questionId}`
        var component = (this.$refs as any)[refValue]

        component[0].deleteExampleImg(this.selectedAttachment.exampleId)
        this.closeModal()
    }


    closeModal() {
        // 디테일 값 초기화
        this.selectedAttachment = Object.create(null)
        this.showModal = false

    }

    setLastFocus() {

        let refs = (this.$refs as any)

        let lastQuestionId = Math.max.apply(Math, this.createdQuestions.map(function (o) { return <number>o.questionInfo.surveyQuestionId; }))
        refs[`questionForm${lastQuestionId}`][0].$el.getElementsByTagName('input')[0].focus()
    }

    async goPreview() {
        if (this.validation()) {
            this.$router.push({
                name: "SurveyRead",
                query: {
                    ...this.$route.query
                },
                params: { isPreview: 'true' }
            })
        }
        else {
            await (window as any).alertAsync(
                this.$t('빈_값이나_보기_없음').toString())
        }
    }
}
